import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: "登录区块链浏览器",
      keepAlive: true,
    },
    component: () => import("../views/login.vue"),
  },
  {
    path: "/index",
    name: "index",
    meta: {
      title: "区块链浏览器",
      keepAlive: true,
    },
    component: () => import("../views/index.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/detail.vue"),
    meta: {
      title: "详情",
    },
  },
  {
    path: "/moreBlock",
    name: "moreBlock",
    component: () => import("../views/moreBlock.vue"),
  },
];

const router = new VueRouter({
  routes,
});

// 前置守卫
// router.beforeEach((to, form, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   next();
// });

export default router;
