import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import ElementUI from "element-ui";
import VueClipBoard from "vue-clipboard2";
import "./assets/css/_common.scss";
import serve from "./utils/request";

Vue.config.productionTip = false;
Vue.prototype.$serve = serve;

Vue.use(ElementUI);
Vue.use(VueClipBoard);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
