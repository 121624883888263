import router from './router'
import Cookies from 'js-cookie'

router.beforeEach((to, from, next) => {
  // const useCookie = JSON.parse(to.query.useCookie)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
  const token = Cookies.get('token') || null
  if (token) {
    next()
  } else {
    next()
    // if (to.path === '/') {
    //   next()
    // } else {
    //   next('/')
    // }
  }
  // const token = Cookie.get('token') || null
  // if (token) {
  //   next()
  // } else {
  //   next({ path: '/' })
  // }
  // if (
  //   to.path !== "/" &&
  //   useCookie.rolesIds === "undefined" &&
  //   useCookie.permissions === "undefined" &&
  //   useCookie.user === "undefined"
  // ) {
  //
  // } else {

  // }
})
