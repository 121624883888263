import axios from 'axios'

const serve = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: '/api',
  // baseURL: 'http://192.168.0.133:8777',
  // baseURL: "/api",
  timeout: 15000
})

// serve.interceptors.request.use(
//   // (config) => {
//   //   if (config.url !== "/login" || localStorage.getItem("Authorization")) {
//   //     config.headers.Authorization =
//   //       "Bearer " + localStorage.getItem("Authorization");
//   //   }
//   //   return config;
//   // },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default serve
