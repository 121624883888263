import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorization: localStorage.getItem('Authorization')
      ? localStorage.getItem('Authorization')
      : ''
  },
  getters: {},
  mutations: {
    changeLogin(state, user) {
      state.Authorization = user.Authorization
      // localStorage.setItem("Authorization", user.Authorization);
    }
  },
  actions: {},
  modules: {}
})
